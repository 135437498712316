import React, { useState } from "react";
import "./App.css";
import { Container } from "@mui/system";
import logo from "./assets/images/logo.png";
// import LatestForm from "./components/latestForm/latestForm";
import StepperForm from "./components/stepperForm";

const App = () => {
  // const [sendData, setSendData] = useState({});
  // const valuehandler = (dataValues) => {
  //   setSendData({...dataValues});
  // }
  // console.log(sendData);

  // https://mortgagepro-fullapplication.herokuapp.com/send
  // http://localhost:4000/send

  const [spinner, setSpinner] = useState(false);
  const [screen, setScreen] = useState(false);

  const sendEmail = (data) => {
    setSpinner(true);
    console.log("Data Sent", data);
    fetch("https://morgtageappshane.herokuapp.com/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then(function (data) {
      setSpinner(false);
      setScreen(true);
    });
  };

  return (
    <div className="mortGageContainer">
      <Container maxWidth="sm" className="mortgageBlock">
        <img src={logo} alt="Logo" style={{ margin: "20px 0 0" }} />
        <StepperForm
          values={sendEmail}
          spinnerCheck={spinner}
          endScreen={screen}
        />
      </Container>
    </div>
  );
};

export default App;
